import Header from "../navigation/Header";
import Footer from "../navigation/Footer";

interface Props {
    children: React.ReactNode;
}

export default function PageLayoutLanding({ children }: Props) {
    return (
        <main
            className="scroll-smooth bg-blackln"
            data-aos-mirror="true"
        >
            <Header />
            <div>{children}</div>
            <Footer />
        </main>
    );
}
