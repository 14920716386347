import Image from 'next/image';
import footerlogo from '@public/img/landing/footer/luckynetwork_footerlogo.png';

// Icons
import { SiTiktok } from 'react-icons/si';
import { AiFillInstagram, AiFillYoutube } from 'react-icons/ai';

import Link from 'next/link';

export default function Footer() {
	const links = [
		{
			name: 'Home',
			link: '/'
		},
		{
			name: 'Store',
			link: '/store'
		},
		{
			name: 'Rules',
			link: '/rules'
		},
		{
			name: 'Staff',
			link: '/staff'
		}
	];

	const year = new Date().getFullYear();
	return (
		<>
			<footer className="bg-fifth z-10 py-[80px]">
				<div className="container max-w-screen-2xl mx-auto">
					<div className="flex flex-wrap px-10 justify-center items-center">
						{/* Image */}
						<div className="lg:w-3/12 w-full flex flex-wrap gap-10 items-center mb-5 lg:mb-0 order-1">
							<Image
								src={footerlogo}
								alt="footerlogo"
								className="h-[100px] w-[200px]"
							/>
							{/* Line */}
							<div className="hidden lg:inline-block  h-[250px] min-h-[1em] w-0 lg:w-0.5 self-stretch bg-neutral-100 opacity-100 dark:opacity-50"></div>
						</div>
						{/* Text and links */}

						<div className="lg:w-6/12 w-full flex flex-col gap-4 md:px-5  order-3 lg:order-2">
							<div className="flex flex-wrap gap-2 lg:gap-5">
								{links.map((link, index) => (
									<Link
										href={link.link}
										key={index}
									>
										<p className="text-sixth font-nexabold text-xl lg:text-2xl cursor-pointer">
											{
												link.name
											}
										</p>
									</Link>
								))}
							</div>
							<div>
								<p className="text-sixth">
									© {year} Lucky Network All
									Right Reserved
								</p>
							</div>
						</div>
						{/* Social Media */}
						<div className="lg:w-3/12 w-full flex flex-col gap-5 mt-5 md:px-5  mb-5 lg:mb-0  order-2 lg:order-3">
							<h3 className="text-sixth font-nexabold text-xl lg:text-2xl">
								STAY CONNECTED
							</h3>
							{/* Tiktok, Instagram and Youtube */}
							<div className="flex flex-wrap gap-5 lg:gap-0">
								<div className="lg:w-1/3">
									{/* Instagram */}
									<Link
										href="https://www.instagram.com/luckynetwork/"
										target="_blank"
										aria-label="Instagram"
									>
										<div>
											<AiFillInstagram className="text-sixth text-3xl lg:text-3xl cursor-pointer" />
										</div>
									</Link>
								</div>
								<div className="lg:w-1/3">
									{/* Tiktok */}
									<Link
										href="https://www.tiktok.com/@luckynetworkofficial/"
										target="_blank"
										aria-label="Tiktok"
									>
										<div>
											<SiTiktok className="text-sixth text-3xl lg:text-3xl cursor-pointer" />
										</div>
									</Link>
								</div>
								<div className="w-1/3">
									{/* Youtube */}{' '}
									<Link
										href="https://www.youtube.com/@luckynetworkofficial/"
										target="_blank"
										aria-label="Youtube"
									>
										<div>
											<AiFillYoutube className="text-sixth text-3xl lg:text-3xl cursor-pointer" />
										</div>
									</Link>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="flex flex-wrap px-10 justify-center items-center m-auto my-10 lg:block">
					<p className="lg:text-center text-fourth mt-5 text-start">
						This site is protected by reCAPTCHA and the Google{' '}
					</p>
					<p className="lg:text-center text-start text-fourth">
						<Link
							href="https://policies.google.com/privacy"
							target="_blank"
							className="text-fourth underline hover:text-secondary"
						>
							Privacy Policy
						</Link>{' '}
						and{' '}
						<Link
							href="https://policies.google.com/terms"
							target="_blank"
							className="text-fourth underline hover:text-secondary"
						>
							Terms of Service
						</Link>{' '}
						apply.
					</p>
				</div>
			</footer>
		</>
	);
}
